import { AppShell, Header, ScrollArea, useMantineTheme } from "@mantine/core";
import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { AppFooter, AppHeader } from ".";

function AppShellWrap(props: {
  children: React.ReactNode[];
  scrollState?: any;
}) {
  const theme = useMantineTheme();
  const location = useLocation();
  const atHome = location.pathname === "/";

  const [scroll, setScroll] = useState({ x: 0, y: 0 });
  const hasScrolled = !atHome || scroll.y > 180;

  return (
    <ScrollArea type="scroll" onScrollPositionChange={setScroll}>
      <AppShell
        header={
          <Header
            height={100}
            padding="lg"
            sx={{
              backgroundColor: hasScrolled
                ? theme.other.paperBg
                : "transparent",
              border: "none",
              position: "sticky",
              transition: "ease-in-out .5s",
            }}
          >
            <AppHeader hasScrolled={hasScrolled} />
          </Header>
        }
      >
        <div
          style={{
            height: `${atHome ? "360px" : "120px"}`,
            transition: "ease-in-out .5s",
          }}
        />
        {props.children}
      </AppShell>
      <AppFooter />
    </ScrollArea>
  );
}

export default AppShellWrap;
