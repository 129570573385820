import React, { useState, useEffect } from "react";
import { Autocomplete, Divider } from "@mantine/core";
import { RankingComponentBase, TopCollections } from ".";

const dummyCollections = [
  "asac.near",
  "thebullishbulls.near",
  "nearton_nft.near",
  "mrbrownproject.near",
  "secretskelliessociety.near",
  "nearnautnft.near",
];

function CollectionSelector(props: {
  selected?: string;
  onSelect: (s: string) => void;
}) {
  const { selected, onSelect } = props;
  const [inputValue, setInputValue] = useState("");
  const isInitialState = !inputValue || inputValue === "";

  useEffect(() => {
    setInputValue("");
  }, [selected]);

  return (
    <RankingComponentBase direction="column">
      <Autocomplete
        value={inputValue}
        onChange={setInputValue}
        onItemSubmit={(item) => onSelect(item.value)}
        label="Select collection"
        placeholder="Search collections"
        data={isInitialState ? [] : dummyCollections}
        size="lg"
        width="100%"
      />
      <Divider
        orientation="horizontal"
        color="indigo"
        sx={{ margin: "10px 0px" }}
      />
      <TopCollections {...props} />
    </RankingComponentBase>
  );
}

export default CollectionSelector;
