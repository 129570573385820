import React from "react";
import { Box, Button, Grid, Text } from "@mantine/core";

const dummyTopCollections = [
  "asac.near",
  "thebullishbulls.near",
  "nearton_nft.near",
  "mrbrownproject.near",
  "secretskelliessociety.near",
];

function TopCollections(props: {
  selected?: string;
  onSelect: (s: string) => void;
}) {
  const { selected, onSelect } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        minWidth: "300px",
        alignItems: "flex-start",
        justifyContent: "flex-end",
      }}
    >
      <Text weight="bold" sx={{ marginBottom: "10px" }}>
        Top collections
      </Text>
      <Grid columns={1} gutter="xs">
        {dummyTopCollections.map((c) => {
          const isSelected = selected === c;
          return (
            <Grid.Col key={c}>
              <Button
                variant={isSelected ? "gradient" : "outline"}
                color="orange"
                gradient={{ from: "orange", to: "grape" }}
                value={c}
                onClick={() => onSelect(c)}
                sx={{ marginRight: "5px" }}
              >
                {c}
              </Button>
            </Grid.Col>
          );
        })}
      </Grid>
    </Box>
  );
}

export default TopCollections;
