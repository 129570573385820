import { MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
  fontFamily: "Roboto, sans-serif",
  colorScheme: "dark",
  headings: {
    fontFamily: "Roboto Slab",
    fontWeight: 400,
    sizes: {
      h1: {
        fontSize: 48,
      },
      h2: {
        fontSize: 36,
      },
    },
  },
  other: {
    paperBg: "#080c1e",
  },
};
