import React from "react";

function BackgroundPattern() {
  //const patterns = ["square-pattern", "triangle-pattern", "mosaic-pattern"];
  //const img = patterns[Math.floor(Math.random() * patterns.length)];
  const img = "triangle-pattern";
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        zIndex: -10,
        background: `transparent url(media/${img}.png)`,
      }}
    />
  );
}

export default BackgroundPattern;
