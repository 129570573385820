import React from "react";
import { BrowserRouter } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { theme } from "../../styles/theme";
import { BackgroundPattern } from ".";

function AppContext({ children }: { children: JSX.Element }) {
  return (
    <BrowserRouter>
      <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
        <BackgroundPattern />
        {children}
      </MantineProvider>
    </BrowserRouter>
  );
}

export default AppContext;
