import { useMantineTheme } from "@mantine/styles";
import React from "react";
import Helmet from "react-helmet";

function AppHelmet() {
  const theme = useMantineTheme();
  const bgColor = theme.fn.darken(theme.colors.indigo[9], 0.85);
  return (
    <Helmet>
      <style>{`body { background: ${bgColor}; }`}</style>
    </Helmet>
  );
}

export default AppHelmet;
