import { Box, Center, Title } from "@mantine/core";
import React from "react";

function Bots() {
  return (
    <Box sx={{ height: "1000px" }}>
      <Center>
        <Title sx={{ fontWeight: 800, fontSize: 50, marginBottom: "15px" }}>
          We have Discord bots for you
        </Title>
      </Center>
      <Center>
        <Title sx={{ fontWeight: 400, fontSize: 40, color: "orange" }}>
          This site is a work in progress
        </Title>
      </Center>
    </Box>
  );
}

export default Bots;
