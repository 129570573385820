import { Box, Title, Center, TitleProps } from "@mantine/core";
import React from "react";
import { Link, useLocation } from "react-router-dom";

function HeaderButton(props: TitleProps & { text: string; to: string }) {
  const atPath = useLocation().pathname === props.to;
  return (
    <Center sx={{ height: "100%" }}>
      <Box component={Link} to={props.to}>
        <Title
          {...props}
          sx={{
            color: `${atPath ? "orange" : "white"}`,
            transition: "ease-in-out .3s",
            "&:hover": {
              transform: "scale(1.1)",
              WebkitTransform: "scale(1.05)",
              textShadow: "0px 0px 10px grey",
            },
          }}
        >
          {props.text.toUpperCase()}
        </Title>
      </Box>
    </Center>
  );
}

export default HeaderButton;
