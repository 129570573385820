import React from "react";
import ReactDOM from "react-dom";
import { App, AppContext } from "./components/core";
import "./styles/index.css";

ReactDOM.render(
  <AppContext>
    <App />
  </AppContext>,
  document.getElementById("root")
);
