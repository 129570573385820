import { Box, Center, Image } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";

function HomeLogo(props: { small: boolean }) {
  return (
    <Center sx={{ height: "100%" }}>
      <Box sx={{ position: "absolute" }} component={Link} to="/">
        <Image
          sx={{
            position: "relative",
            top: `${props.small ? "10px" : 0}`,
            maxWidth: `${props.small ? "130px" : "300px"}`,
            transition: "ease-in-out .3s",
            transitionDelay: ".2s",
            "&:hover": {
              transform: "scale(1.025) rotate(3deg)",
              WebkitTransform: "scale(1.025) rotate(3deg)",
            },
          }}
          src="/media/dwc-logo.png"
        />
      </Box>
    </Center>
  );
}

export default HomeLogo;
