import React from "react";
import { Box, Center, Text, Title } from "@mantine/core";
import { RankingComponentBase } from "../components/rankings";

function CollectionInformation(props: { selected?: string }) {
  return (
    <RankingComponentBase>
      {props.selected ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Title order={2} sx={{ color: "cyan" }}>
            {props.selected}
          </Title>
        </Box>
      ) : (
        <Center sx={{ padding: "25px", width: "100%" }}>
          <Text weight="bold" size="lg" color="cyan">
            Choose a collection to begin
          </Text>
        </Center>
      )}
    </RankingComponentBase>
  );
}

export default CollectionInformation;
