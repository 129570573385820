import React from "react";
import { Center, createStyles, Grid } from "@mantine/core";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  footer: {
    height: "200px",
    margin: "50px 0px",
    padding: "50px",
    backgroundColor: theme.other.paperBg,
    justifyContent: "center",
    color: `${theme.fn.darken(theme.colors.indigo[3], 0.5)}`,
  },
  footerLink: {
    "&:hover": {
      color: `${theme.fn.darken(theme.colors.indigo[3], 0.25)}`,
      transition: "ease-in-out .1s",
    },
  },
}));

function AppFooter(props: any) {
  const { footer, footerLink } = useStyles().classes;
  return (
    <Grid className={footer}>
      <Grid.Col span={12}>
        <Center>© 2021 DWC</Center>
      </Grid.Col>
      <Grid.Col span={1}>
        <Center component={Link} to="/" className={footerLink}>
          Privacy
        </Center>
      </Grid.Col>
      <Grid.Col span={1}>
        <Center component={Link} to="/" className={footerLink}>
          Terms
        </Center>
      </Grid.Col>
    </Grid>
  );
}

export default AppFooter;
