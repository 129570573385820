import React, { useState } from "react";
import { Grid } from "@mantine/core";
import {
  CollectionSelector,
  CollectionInformation,
} from "../components/rankings";

function Rankings() {
  const [selected, setSelected] = useState<string | undefined>(undefined);
  return (
    <Grid columns={4} sx={{ padding: "2%" }}>
      <Grid.Col span={4} lg={1}>
        <CollectionSelector
          selected={selected}
          onSelect={(val) => setSelected(val)}
        />
      </Grid.Col>
      <Grid.Col span={4} lg={3}>
        <CollectionInformation selected={selected} />
      </Grid.Col>
    </Grid>
  );
}

export default Rankings;
