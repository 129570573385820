import { Grid } from "@mantine/core";
import React from "react";
import { HeaderButton, HomeLogo } from "./header";

function AppHeader(props: { hasScrolled: boolean }) {
  const paddingY = props.hasScrolled ? "10px" : "150px";
  // TODO: responsiveness
  // TODO: burger menu
  return (
    <Grid
      columns={19}
      justify="center"
      sx={{
        padding: `${paddingY} calc(50px + 5%)`,
        transition: "ease-in-out .5s",
      }}
    >
      <Grid.Col span={0} lg={3}>
        <HeaderButton order={3} text="about" to="/about" />
      </Grid.Col>
      <Grid.Col span={5} lg={4}>
        <HeaderButton order={2} text="ranks" to="/rank" />
      </Grid.Col>
      <Grid.Col span={19} md={9} lg={5}>
        <HomeLogo small={props.hasScrolled} />
      </Grid.Col>
      <Grid.Col span={5} lg={4}>
        <HeaderButton order={2} text="bots" to="/bots" />
      </Grid.Col>
      <Grid.Col span={0} lg={3}>
        <HeaderButton order={3} text="contact" to="/contact" />
      </Grid.Col>
    </Grid>
  );
}

export default AppHeader;
