import React from "react";
import { Paper } from "@mantine/core";
import { Property } from "csstype";

function RankingComponentBase(
  props: React.PropsWithChildren<{ direction?: Property.FlexDirection }>
) {
  return (
    <Paper
      sx={(theme) => ({
        backgroundColor: theme.other.paperBg,
        width: "100%",
        padding: "15px",
        display: "flex",
        flexDirection: props.direction,
      })}
    >
      {props.children}
    </Paper>
  );
}

export default RankingComponentBase;
