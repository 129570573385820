import React from "react";
import { Routes, Route } from "react-router-dom";
import { AppHelmet, AppShellWrap } from ".";
import { Bots, Home, Rankings } from "../../pages";

function App() {
  // TODO: Transition between routes
  // https://v5.reactrouter.com/web/example/animated-transitions
  return (
    <AppShellWrap>
      <AppHelmet />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bots" element={<Bots />} />
        <Route path="/rank" element={<Rankings />} />
      </Routes>
    </AppShellWrap>
  );
}

export default App;
